import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'antd/dist/antd.css'
import './App.scss'

import NotFound from './views/404'
import routes, { RouteItem } from './utils/routes'
import 'moment/locale/zh-cn'
import * as moment from 'moment'
import { getUser } from './utils/auth'
moment.locale('zh-cn')

const renderRoute = (route: RouteItem): any => {
  if (route.children && route.children.length > 0) {
    return route.children.map(renderRoute)
  } else {
    return <Route extra key={route.key} path={route.path} component={route.component} />
  }
}

const App: React.FC = () => {
  const userCookie = getUser()
  const [user, setUser] = useState(null)
  useEffect(() => {
    setUser(userCookie)
  }, [])
  console.log('app render')
  return (
    <Router>
      <Switch>
        {routes(user).map(renderRoute)}
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
