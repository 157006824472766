import React from 'react'
import ReactDOM from 'react-dom'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { message, ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { getToken } from './utils/auth'

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      message.error(graphQLErrors[0].message)
    }
    if (networkError) {
      message.error(networkError.message)
    }
  },
  request: operation => {
    const token = getToken()
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
