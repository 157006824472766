import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import GuideForm from '../form'
import Layout from '../../../components/layout'
import { message, Button } from 'antd'
// import { getUser } from '../../../utils/auth'
import moment from 'moment'

const CREATE_BLOCK_SCHEDULE = gql`
  mutation CreateBlockSchedule($data: BlockScheduleCreateInput!) {
    createBlockSchedule(data: $data) {
      id
    }
  }
`

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [handleCreateBlockSchedule, { loading }] = useMutation(CREATE_BLOCK_SCHEDULE, {
    onCompleted: data => {
      message.success('添加成功')
      history.push('/block-schedule/list')
    }
  })

  const handleSubmit = async (values: any) => {
    const { note, detail, priority, startDate, endDate } = values
    // const user = getUser()
    await handleCreateBlockSchedule({
      variables: {
        data: {
          note,
          executeSchedules: {
            create: Object.keys(detail).map(userId => ({
              note: '优先级执行计划',
              startDate,
              endDate,
              priority,
              user: {
                connect: {
                  id: userId
                }
              },
              executeScheduleDetail: {
                create: detail[userId].map((item: any) => {
                  return {
                    priority,
                    startDate: moment(item.startDate).toISOString(),
                    endDate: moment(item.endDate).toISOString(),
                    groupMember: {
                      connect: {
                        id: item.groupMember
                      }
                    },
                    user: {
                      connect: {
                        id: userId
                      }
                    }
                  }
                })
              }
            }))
          }
        }
      }
    })
  }

  return (
    <Layout
      extra={
        <Link to="/block-schedule/list">
          <Button type="primary" icon="left">
            返回
          </Button>
        </Link>
      }
      title="添加优先执行计划"
    >
      <GuideForm loading={loading} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withRouter(Login)
