import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import DateTimeDurationTable from './table'
import Layout from '../../../components/layout'
import { Button } from 'antd'

export const GET_DATETIME_DURATION = gql`
  query DateTimeDurations(
    $where: DateTimeDurationWhereInput
    $orderBy: DateTimeDurationOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    dateTimeDurations(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      note
      dateTimeDurationDetails {
        startDate
        endDate
      }
    }
  }
`

const DateTimeDurationList: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, data, refetch } = useQuery(GET_DATETIME_DURATION, {
    variables: {
      orderBy: 'createdAt_DESC'
    },
    fetchPolicy: 'cache-and-network'
  })
  return (
    <Layout
      title="时间段列表"
      extra={
        <Link to="/date-time-duration/add">
          <Button type="primary" icon="plus">
            新增
          </Button>
        </Link>
      }
    >
      {data && (
        <DateTimeDurationTable
          onDeleteSuccess={() => refetch()}
          loading={loading}
          dataSource={data.dateTimeDurations}
        />
      )}
    </Layout>
  )
}

export default withRouter(DateTimeDurationList)
