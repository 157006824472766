import React, { Fragment, useState, useCallback } from 'react'
import { Form, Input, Button, Modal, List } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import TimePickerForm from '../../schedule/form/timePicker'
import { Moment } from 'moment'

type FormProps = {
  loading?: boolean
  initValue?: { id: string; dateTimeDurationName: string; password: string; phone: string; role: string }
  form: WrappedFormUtils
  onSubmit: (values: object) => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}

const DateTimeDurationForm: React.FC<FormProps> = ({ loading, onSubmit, form, initValue }) => {
  const { getFieldDecorator } = form

  const [dateDurationModalVisible, setDateDurationModalVisible] = useState(false)
  const [dateTimeDurationList, setDateTimeDurationList] = useState([])

  const toggledateDurationModal = useCallback(() => {
    setDateDurationModalVisible(state => !state)
  }, [setDateDurationModalVisible])

  const handleTimePickerSubmit = useCallback((e: any) => {
    setDateDurationModalVisible(false)
    setDateTimeDurationList(e)
  }, [])

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      form.validateFields(async (err: any, values: any) => {
        if (!err) {
          const { note } = values
          onSubmit({ note, dateTimeDurationList })
        }
      })
    },
    [dateTimeDurationList, form]
  )

  return (
    <Fragment>
      <Modal title={`编辑时间`} footer={null} visible={dateDurationModalVisible} onCancel={toggledateDurationModal}>
        <TimePickerForm onSubmit={handleTimePickerSubmit} />
      </Modal>
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="备注">
          {getFieldDecorator('note', {
            initialValue: initValue && initValue.dateTimeDurationName,
            rules: [{ required: true, message: '请输入备注' }]
          })(<Input placeholder="备注" autoComplete="new-sh" />)}
        </Form.Item>
        {dateTimeDurationList.length > 0 && (
          <Form.Item label="时间段">
            <List
              bordered
              dataSource={dateTimeDurationList}
              renderItem={(item: { startDate: Moment; endDate: Moment }) => (
                <List.Item>
                  {item.startDate.format('HH:mm:ss')} ~ {item.endDate.format('HH:mm:ss')}
                </List.Item>
              )}
            />
          </Form.Item>
        )}
        <Form.Item
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: 16, offset: 8 }
          }}
        >
          <Button onClick={toggledateDurationModal} style={{ marginRight: 20 }}>
            编辑时间段
          </Button>
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export default Form.create<FormProps>()(DateTimeDurationForm)
