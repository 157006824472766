import React, { useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_USERS } from '../list'
import { Select } from 'antd'
const { Option } = Select

interface UserSelectProps {
  onChange?: any
  value?: any
}

const UserSelect: React.FC<UserSelectProps> = ({ onChange, value }) => {
  const { loading, data } = useQuery(GET_USERS, {
    variables: {},
    fetchPolicy: 'cache-and-network'
  })

  const handleChange = useCallback(
    (value, option) => {
      onChange && onChange(value)
    },
    [onChange]
  )

  return (
    <Select loading={loading} mode="multiple" style={{ width: '100%' }} value={value} onChange={handleChange}>
      {data && data.users && data.users.map((item: any) => <Option value={item.id}>{item.userName}</Option>)}
    </Select>
  )
}

export default UserSelect
