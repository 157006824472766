import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { Form, Input, Button, Select, Divider, Descriptions, TimePicker, Icon } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import moment from 'moment'
import * as R from 'ramda'

type FormProps = {
  loading?: boolean
  initialValue?: ScheduleDetail[]
  form: WrappedFormUtils
  onSubmit: (values: object) => void
}

let formValueInitialValue = {
  id: '',
  startDate: null,
  endDate: null,
  schedule: ''
}

const TimePickerForm: React.FC<FormProps> = ({ loading, onSubmit, form, initialValue }) => {
  const { getFieldDecorator } = form
  const [formValue, setFormValue] = useState<ScheduleDetail[]>()

  const cloneFormValue = () => {
    formValueInitialValue.id = (+new Date()).toString()
    return JSON.parse(JSON.stringify(formValueInitialValue))
  }

  useEffect(() => {
    setFormValue(initialValue)
  }, [initialValue])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    form.validateFields(async (err: any, values: any) => {
      let result = []
      if (!err) {
        for (const key in values.startDate) {
          if (values.startDate.hasOwnProperty(key)) {
            result.push({
              id: key,
              startDate: values.startDate[key],
              endDate: values.endDate[key]
            })
          }
        }
      }
      onSubmit(result)
    })
  }

  const handleAddFormValue = useCallback(() => {
    setFormValue(value => {
      if (value) {
        return [...value, cloneFormValue()]
      } else {
        return [cloneFormValue()]
      }
    })
  }, [])

  const handleDeleteFormValue = useCallback(
    id => () => {
      setFormValue(formValue => {
        if (formValue) {
          return formValue.filter(item => item.id !== id)
        }
      })
    },
    []
  )

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        {formValue &&
          formValue.length > 0 &&
          formValue.map((item, index) => (
            <Form.Item key={item.id} style={{ marginBottom: 0 }}>
              <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 25px)' }}>
                {getFieldDecorator(`startDate[${item.id}]`, {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: '请选择开始时间'
                    }
                  ],
                  initialValue: !R.isNil(item.startDate) ? moment(item.startDate) : null
                })(<TimePicker style={{ width: '100%' }} />)}
              </Form.Item>
              <span style={{ display: 'inline-block', width: '20px', textAlign: 'center' }}>-</span>
              <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 25px)' }}>
                {getFieldDecorator(`endDate[${item.id}]`, {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: '请选择结束时间'
                    }
                  ],
                  initialValue: !R.isNil(item.endDate) ? moment(item.endDate) : null
                })(<TimePicker style={{ width: '100%' }} />)}
              </Form.Item>
              <span
                onClick={handleDeleteFormValue(item.id)}
                style={{ display: 'inline-block', width: '30px', cursor: 'pointer', textAlign: 'center' }}
              >
                <Icon type="minus-circle-o" />
              </span>
            </Form.Item>
          ))}
        <Form.Item>
          <Button onClick={handleAddFormValue} type="dashed" style={{ width: 'calc(100% - 30px)' }}>
            <Icon type="plus" /> 添加
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export default Form.create<FormProps>()(TimePickerForm)
