import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import GroupMemberTable from './table'
import Layout from '../../../components/layout'
import { Button } from 'antd'
import { getUser } from '../../../utils/auth'

export const GET_GROUPMEMBERS = gql`
  query GetGroupMembers(
    $where: GroupMemberWhereInput
    $orderBy: GroupMemberOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    groupMembers(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      userName
      password
      effectiveAt
      expiredAt
      isDefault
      groupMemberTrips {
        id
        groupListItemId
        userName
        userId
        name
        created_at
        start_at
        end_at
      }
      user {
        id
      }
    }
  }
`

const GroupMemberList: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, data, refetch } = useQuery(GET_GROUPMEMBERS, {
    variables: {
      orderBy: 'createdAt_DESC',
      where: {
        user: {
          id: getUser().id
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  })
  return (
    <Layout
      title="团员列表"
      extra={
        <Link to="/groupMembers/add">
          <Button type="primary" icon="plus">
            新增
          </Button>
        </Link>
      }
    >
      {data && <GroupMemberTable onDeleteSuccess={() => refetch()} loading={loading} dataSource={data.groupMembers} />}
    </Layout>
  )
}

export default withRouter(GroupMemberList)
