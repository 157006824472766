import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import ExecuteScheduleTable from './table'
import Layout from '../../../components/layout'
import { Button } from 'antd'
import { getUser } from '../../../utils/auth'

export const GET_EXECUTE_SCHEDULE = gql`
  query ExecuteSchedules(
    $where: ExecuteScheduleWhereInput
    $orderBy: ExecuteScheduleOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    executeSchedules(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      startDate
      endDate
      priority
      note
      executeScheduleDetail {
        groupMember {
          userName
        }
        startDate
        endDate
      }
    }
  }
`

const ExecuteScheduleList: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, data, refetch } = useQuery(GET_EXECUTE_SCHEDULE, {
    variables: {
      orderBy: 'createdAt_DESC',
      where: {
        user: {
          id: getUser().id
        },
        priority: 0
      }
    },
    fetchPolicy: 'cache-and-network'
  })
  return (
    <Layout
      title="执行计划"
      extra={
        <Link to="/schedules-builder/add">
          <Button type="primary" icon="plus">
            新增
          </Button>
        </Link>
      }
    >
      {data && (
        <ExecuteScheduleTable onDeleteSuccess={() => refetch()} loading={loading} dataSource={data.executeSchedules} />
      )}
    </Layout>
  )
}

export default withRouter(ExecuteScheduleList)
