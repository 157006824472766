import React, { useCallback, useState, useEffect } from 'react'
import { Form, Input, Button, List, Typography, Row, Col, InputNumber } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import MultiDatePicker from '../../../components/multi-datepicker'
import UserSelect from '../../user/select'
import DateTimeDurationSelect from '../../date-time-duration/select'
import moment, { Moment } from 'moment'
import * as R from 'ramda'
import GroupMemberSelect from '../../groupMember/select'

type FormProps = {
  loading?: boolean
  initValue?: { id: string; userName: string; password: string; role: string }
  form: WrappedFormUtils
  onSubmit: (values: any) => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 20 },
    sm: { span: 20 }
  }
}

const UserForm: React.FC<FormProps> = ({ loading, onSubmit, form, initValue }) => {
  const { getFieldDecorator } = form
  // 时间段
  const [dateTimeDurationDetail, setDateTimeDurationDetail] = useState<any[]>([])
  // 日期
  const [daysDetail, setDaysDetail] = useState<any[]>([])
  const [groupMember, setGroupMember] = useState([])
  const [daysGroupMember, setDaysGroupMember] = useState()
  const [finalDateDetail, setFinalDateDetail] = useState<any[]>([])

  useEffect(() => {
    const groupMemberClone = R.clone(groupMember)
    let computedGroupMember: any[] = []
    if (groupMember.length > 0) {
      while (computedGroupMember.length < daysDetail.length) {
        computedGroupMember = computedGroupMember.concat(groupMemberClone)
      }
    }
    setDaysGroupMember(computedGroupMember)
  }, [groupMember, daysDetail])

  const toStartISODate = (date: Moment) => moment(date.format('YYYY-MM-DD 00:00:00')).toISOString()
  const toEndISODate = (date: Moment) => moment(date.format('YYYY-MM-DD 23:59:50')).toISOString()

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      form.validateFields(async (err: any, values: any) => {
        if (!err) {
          const { blockUserList, note, priority } = values
          const sortedDay = daysDetail.map(day => moment(day)).sort((a, b) => (a.isBefore(b) ? -1 : 1))
          const detail: { [key: string]: any[] } = {}
          for (const user of blockUserList) {
            detail[user] = finalDateDetail
          }
          onSubmit &&
            onSubmit({
              note,
              detail,
              priority,
              startDate: toStartISODate(sortedDay[0]),
              endDate: toEndISODate(sortedDay[sortedDay.length - 1])
            })
        }
      })
    },
    [dateTimeDurationDetail, form, finalDateDetail, onSubmit, daysDetail]
  )

  const handleMultiDateChange = useCallback(
    (dayList: Date[]) => {
      setDaysDetail(dayList.map(day => day))
    },
    [setDaysDetail]
  )

  useEffect(() => {
    let finalDateList = []
    for (let i = 0; i < daysDetail.length; i++) {
      const date = daysDetail[i]
      for (const duration of dateTimeDurationDetail) {
        finalDateList.push({
          startDate: moment(date).format(`YYYY-MM-DD ${moment(duration.startDate).format('HH:mm:ss')}`),
          endDate: moment(date).format(`YYYY-MM-DD ${moment(duration.endDate).format('HH:mm:ss')}`),
          groupMember: daysGroupMember[i]
        })
      }
    }
    setFinalDateDetail(finalDateList)
  }, [dateTimeDurationDetail, daysDetail, daysGroupMember])

  const handleDetailChange = useCallback(
    e => {
      setDateTimeDurationDetail(e)
    },
    [setDateTimeDurationDetail]
  )

  return (
    <Row gutter={20}>
      <Col span={15}>
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <Form.Item label="时间段">
            {getFieldDecorator('dateDuration', {
              rules: [{ required: true, message: '选择时间段' }]
            })(<DateTimeDurationSelect onDetailChange={handleDetailChange} />)}
          </Form.Item>
          <Form.Item label="日期">
            {getFieldDecorator('dateList', {
              rules: [{ required: true, message: '选择日期' }]
            })(<MultiDatePicker onChange={handleMultiDateChange} />)}
          </Form.Item>
          <Form.Item label="应用到用户">
            {getFieldDecorator('blockUserList', {
              rules: [{ required: true, message: '执行用户' }]
            })(<UserSelect />)}
          </Form.Item>
          <Form.Item label="执行账号">
            {getFieldDecorator('blockUserList-', {
              rules: [{ required: true, message: '选择执行账号 ' }]
            })(<GroupMemberSelect onChange={setGroupMember} />)}
          </Form.Item>
          <Form.Item label="优先级">
            {getFieldDecorator('priority', {
              initialValue: 1,
              rules: [{ required: true, message: '备注' }]
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label="备注">
            {getFieldDecorator('note', {
              rules: [{ required: true, message: '备注' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 4, offset: 4 },
              sm: { span: 4, offset: 4 }
            }}
          >
            <Button loading={loading} htmlType="submit" type="primary">
              创建
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={9}>
        {!R.isEmpty(dateTimeDurationDetail) && (
          <List
            header="已选择时间段"
            bordered
            dataSource={dateTimeDurationDetail}
            renderItem={(item: any, index) => (
              <List.Item>
                <Typography.Text mark>
                  <span style={{ width: 30, display: 'inline-block', textAlign: 'center' }}>{index + 1}.</span>
                </Typography.Text>
                {`${moment(item.startDate).format('HH:mm:ss')} ~ ${moment(item.endDate).format('HH:mm:ss')}`}
              </List.Item>
            )}
          />
        )}
        {!R.isEmpty(finalDateDetail) && (
          <List
            style={{ marginTop: 20 }}
            header="生效时间"
            bordered
            dataSource={finalDateDetail}
            renderItem={(item: any, index) => (
              <List.Item>
                <Typography.Text mark>
                  <span style={{ width: 30, display: 'inline-block', textAlign: 'center' }}>{index + 1}.</span>
                </Typography.Text>
                {`${moment(item.startDate).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(item.endDate).format(
                  'YYYY-MM-DD HH:mm:ss'
                )}`}
              </List.Item>
            )}
          />
        )}
      </Col>
    </Row>
  )
}

export default Form.create<FormProps>()(UserForm)
