import React from 'react'
import { Table, Divider, message, Popconfirm, Button } from 'antd'
import { TableProps, ColumnProps } from 'antd/lib/table/interface'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'

const DELETE_GUIDE = gql`
  mutation DeleteGuide($where: GuideWhereUniqueInput!) {
    deleteGuide(where: $where) {
      id
    }
  }
`

const Update_RISHANG_GUIDE = gql`
  mutation UpdateRishangGuideTripInfo($guideId: String!) {
    updateRishangGuideTripInfo(guideId: $guideId) {
      id
    }
  }
`

type UserTableProps = {
  onDeleteSuccess: () => void
}

export const rowClassName = (record: any) => {
  if (record.end_at && moment(record.end_at).isBefore(moment())) {
    return 'row-expired'
  }
  if (moment(record.start_at).isBefore(moment()) && moment(record.end_at).isAfter(moment())) {
    return 'row-actived'
  }
  if (record.startDate && moment(record.endDate).isBefore(moment())) {
    return 'row-expired'
  }
  if (moment(record.startDate).isBefore(moment()) && moment(record.endDate).isAfter(moment())) {
    return 'row-actived'
  }
  return ''
}

const UserTable: React.FC<UserTableProps & TableProps<any>> = ({ onDeleteSuccess, ...rest }) => {
  const [handleDelete, { loading }] = useMutation(DELETE_GUIDE, {
    onCompleted: data => {
      message.success('删除成功')
      onDeleteSuccess()
    }
  })

  const [handleUpdateRishang, { loading: updateRishangLoading }] = useMutation(Update_RISHANG_GUIDE, {
    onCompleted: data => {
      message.success('更新成功')
      onDeleteSuccess()
    }
  })

  const expandedRowRender = (record: { trips: any[] }) => {
    const columns = [
      {
        title: '行程名称',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '领队名',
        dataIndex: 'userName',
        key: 'userName'
      },
      {
        title: '开始时间',
        dataIndex: 'start_at',
        key: 'start_at',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: '结束时间',
        dataIndex: 'end_at',
        key: 'end_at',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    ]
    return (
      <Table rowKey="id" rowClassName={rowClassName} columns={columns} dataSource={record.trips} pagination={false} />
    )
  }

  const columns: ColumnProps<any>[] = [
    {
      title: '领队日上登录名',
      dataIndex: 'userName',
      key: 'userName',
      align: 'left'
    },
    {
      title: '总积分',
      dataIndex: 'guideIntegral.total',
      key: 'guideIntegral.total',
      align: 'left'
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text: any, record: any) => (
        <span>
          <Popconfirm
            placement="leftBottom"
            title="确认删除？"
            onConfirm={() => handleDelete({ variables: { where: { id: record.id } } })}
            okText="删除"
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            placement="leftBottom"
            title="确认更新？"
            onConfirm={() => handleUpdateRishang({ variables: { guideId: record.id } })}
            okText="确认"
            cancelText="取消"
          >
            <Button loading={updateRishangLoading} type="link">
              更新行程/积分信息
            </Button>
          </Popconfirm>
        </span>
      )
    }
  ]
  return <Table rowKey="id" {...rest} expandedRowRender={expandedRowRender} columns={columns} />
}

export default UserTable
