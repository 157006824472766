import React from 'react'
import { Table, Divider, message, Popconfirm } from 'antd'
import { TableProps } from 'antd/lib/table/interface'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const DELETE_GUIDE = gql`
  mutation DeleteSchedule($where: ScheduleWhereUniqueInput!) {
    deleteSchedule(where: $where) {
      id
    }
  }
`

type ScheduleTableProps = {
  onDeleteSuccess: () => void
}

const ScheduleTable: React.FC<ScheduleTableProps & TableProps<any>> = ({ onDeleteSuccess, ...rest }) => {
  const [handleDelete, { loading }] = useMutation(DELETE_GUIDE, {
    onCompleted: data => {
      message.success('删除成功')
      onDeleteSuccess()
    }
  })
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '操作',
      key: 'action',
      render: (text: any, record: any) => (
        <span>
          <Link to={`/schedules/${record.id}`}>编辑</Link>
          <Divider type="vertical" />
          <Popconfirm
            placement="leftBottom"
            title="确认删除？"
            onConfirm={() => handleDelete({ variables: { where: { id: record.id } } })}
            okText="删除"
            cancelText="取消"
          >
            <a>删除</a>
          </Popconfirm>
        </span>
      )
    }
  ]
  return <Table rowKey="id" {...rest} columns={columns} />
}

export default ScheduleTable
