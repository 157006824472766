import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { getUser } from '../../../utils/auth'
import { Select } from 'antd'
import moment from 'moment'
import * as R from 'ramda'

export const GET_TRIPS = gql`
  query getTrips(
    $where: TripWhereInput
    $orderBy: TripOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    trips(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) {
      id
      groupListItemId
      userName
      userId
      name
      created_at
      start_at
      end_at
    }
  }
`

const { Option } = Select

interface TripSelectProps {
  onChange?: any
  onItemChange?: any
}

const TripSelect: React.FC<TripSelectProps> = ({ onChange, onItemChange }) => {
  const { loading, data } = useQuery(GET_TRIPS, {
    variables: {
      where: {
        user: {
          id: getUser().id
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  })
  const handleChange = (e: any) => {
    console.log(e)
    const item = R.find(R.propEq('groupListItemId', e))(data.trips)
    onItemChange && onItemChange(item)
    onChange && onChange(e)
  }
  return (
    <div className="">
      <Select loading={loading} style={{ width: '100%' }} onChange={handleChange}>
        {data &&
          data.trips &&
          data.trips.map((item: any) => (
            <Option key={item.groupListItemId} value={item.groupListItemId}>
              <span>
                {`${item.userName} - ${item.name} 团期：[${moment(item.start_at).format(
                  'YYYY-MM-DD HH:mm:ss'
                )} ~ ${moment(item.end_at).format('YYYY-MM-DD HH:mm:ss')}]`}
              </span>
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default TripSelect
