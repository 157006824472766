import React from 'react'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import BlockScheduleTable from './table'
import { graphql } from '@apollo/react-hoc'
import Layout from '../../../components/layout'
import { Button } from 'antd'
import { getUser } from '../../../utils/auth'
import { compose, withProps } from 'recompose'

export const GET_BLOCK_SCHEDULES = gql`
  query getBlockSchedule(
    $where: BlockScheduleWhereInput
    $orderBy: BlockScheduleOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    blockSchedules(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      note
      executeSchedules {
        id
        note
        startDate
        endDate
        priority
        user {
          userName
        }
        executeScheduleDetail {
          id
          priority
          groupMember {
            id
            userName
          }
          user {
            userName
          }
          startDate
          endDate
        }
      }
    }
  }
`

type Props = {} & RouteComponentProps & any

const BlockScheduleList: React.FC<Props> = ({ history, blockScheduleQuery: { loading, refetch, blockSchedules } }) => {
  return (
    <Layout
      title="优先执行列表"
      extra={
        <Link to="/block-schedule/add">
          <Button type="primary" icon="plus">
            新增
          </Button>
        </Link>
      }
    >
      {blockSchedules && blockSchedules.length > 0 && (
        <BlockScheduleTable onDeleteSuccess={() => refetch()} loading={loading} dataSource={blockSchedules} />
      )}
    </Layout>
  )
}

const withBlockScheduleProps = withProps(() => ({
  user: getUser()
}))

export default compose<any, any>(
  withRouter,
  withBlockScheduleProps,
  graphql<any>(GET_BLOCK_SCHEDULES, {
    name: 'blockScheduleQuery',
    options: props => ({
      variables: {
        orderBy: 'createdAt_DESC',
        where: {
          // user: {
          //   id: props.user.id
          // }
        }
      },
      fetchPolicy: 'cache-and-network'
    })
  })
)(BlockScheduleList)
