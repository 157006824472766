import React, { useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_GROUPMEMBERS } from '../list'
import { Select } from 'antd'
import { getUser } from '../../../utils/auth'

const { Option } = Select

interface GroupMemberSelectProps {
  onChange?: any
  value?: any
  onDetailChange?: any
}

const GroupMemberSelect: React.FC<GroupMemberSelectProps> = ({ onChange, value, onDetailChange }) => {
  const { loading, data } = useQuery(GET_GROUPMEMBERS, {
    variables: {
      orderBy: 'createdAt_DESC',
      where: {
        user: {
          id: getUser().id
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const handleChange = useCallback(
    value => {
      onChange && onChange(value)
    },
    [onChange, data]
  )

  return (
    <Select loading={loading} mode="multiple" style={{ width: '100%' }} value={value} onChange={handleChange}>
      {data &&
        data.groupMembers &&
        data.groupMembers.map((groupMember: any) => (
          <Option key={groupMember.id} value={groupMember.id}>
            {groupMember.userName}
          </Option>
        ))}
    </Select>
  )
}

export default GroupMemberSelect
