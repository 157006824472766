import React from 'react'
import { Table, Divider, message, Popconfirm } from 'antd'
import { TableProps } from 'antd/lib/table/interface'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'

const DELETE_USER = gql`
  mutation DeleteDateTimeDuration($where: DateTimeDurationWhereUniqueInput!) {
    deleteDateTimeDuration(where: $where) {
      id
    }
  }
`

type DateTimeDurationTableProps = {
  onDeleteSuccess: () => void
}

export const expandedDateTimeDurationDetailsRowRender = (record: { dateTimeDurationDetails: any[] }) => {
  const columns = [
    {
      title: '开始时间',
      dataIndex: 'startDate',
      key: 'endDate',
      render: (text: string) => moment(text).format('HH:mm:ss')
    },
    {
      title: '结束时间',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text: string) => moment(text).format('HH:mm:ss')
    }
  ]
  return <Table columns={columns} dataSource={record.dateTimeDurationDetails} pagination={false} />
}

const DateTimeDurationTable: React.FC<DateTimeDurationTableProps & TableProps<any>> = ({
  onDeleteSuccess,
  ...rest
}) => {
  const [handleDelete, { loading }] = useMutation(DELETE_USER, {
    onCompleted: data => {
      message.success('删除成功')
      onDeleteSuccess()
    }
  })
  const columns = [
    {
      title: '备注',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: '操作',
      key: 'action',
      render: (text: any, record: any) => (
        <span>
          <Popconfirm
            placement="leftBottom"
            title="确认删除？"
            onConfirm={() => handleDelete({ variables: { where: { id: record.id } } })}
            okText="删除"
            cancelText="取消"
          >
            <a>删除</a>
          </Popconfirm>
        </span>
      )
    }
  ]
  return <Table expandedRowRender={expandedDateTimeDurationDetailsRowRender} rowKey="id" {...rest} columns={columns} />
}

export default DateTimeDurationTable
