import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import GuideForm from '../form'
import Layout from '../../../components/layout'
import { message, Button } from 'antd'
import { getUser } from '../../../utils/auth'

const ADD_RISHANG_GUIDE_AND_TRIPS = gql`
  mutation AddRishangGuideAndTrips($userName: String!, $password: String!, $user: String!) {
    addRishangGuideAndTrips(userName: $userName, password: $password, user: $user) {
      id
    }
  }
`

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [handleAdd, { loading }] = useMutation(ADD_RISHANG_GUIDE_AND_TRIPS, {
    onCompleted: data => {
      message.success('添加成功')
      history.push('/guides/list')
    }
  })

  const handleSubmit = async (values: object) => {
    const user = getUser()
    await handleAdd({ variables: { ...values, user: user.id } })
  }

  return (
    <Layout
      extra={
        <Link to="/guides/list">
          <Button type="primary" icon="left">
            返回领队列表
          </Button>
        </Link>
      }
      title="添加领队账号"
    >
      <GuideForm loading={loading} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withRouter(Login)
