import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import UserTable from './table'
import Layout from '../../../components/layout'
import { Button } from 'antd'

export const GET_USERS = gql`
  query GetUsers(
    $where: UserWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    users(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) {
      id
      userName
      password
      role
      phone
    }
  }
`

const UserList: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, data, refetch } = useQuery(GET_USERS, {
    variables: {
      orderBy: 'createdAt_DESC'
    },
    fetchPolicy: 'cache-and-network'
  })
  return (
    <Layout
      title="用户列表"
      extra={
        <Link to="/users/add">
          <Button type="primary" icon="plus">
            新增
          </Button>
        </Link>
      }
    >
      {data && <UserTable onDeleteSuccess={() => refetch()} loading={loading} dataSource={data.users} />}
    </Layout>
  )
}

export default withRouter(UserList)
