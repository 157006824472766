import Cookies from 'js-cookie'

enum cookieKeys {
  user = 'user',
  token = 'token'
}

const setToken = (token: string) => Cookies.set(cookieKeys.token, token, { expires: 7 })

const setUser = (user: object) => Cookies.set(cookieKeys.user, encodeURI(JSON.stringify(user)), { expires: 7 })

const setLoginCookie = (token: string, user: object) => {
  setToken(token)
  setUser(user)
}

const clearLoginCookie = () => {
  Cookies.remove(cookieKeys.token)
  Cookies.remove(cookieKeys.user)
}

const getToken = () => Cookies.get(cookieKeys.token)

const getUser = () => {
  const user = Cookies.get(cookieKeys.user)
  if (user) {
    return JSON.parse(decodeURI(user))
  }
}

const isLogin = () => !!Cookies.get(cookieKeys.token)

export { setLoginCookie, clearLoginCookie, getToken, getUser, isLogin }
