import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import GroupMemberForm from '../form'
import Layout from '../../../components/layout'
import { message, Button } from 'antd'
import { getUser } from '../../../utils/auth'

const GET_GROUP_MEMBER = gql`
  query GetGroupMember($where: GroupMemberWhereUniqueInput!) {
    groupMember(where: $where) {
      id
      password
      userName
      effectiveAt
      expiredAt
      isDefault
      linkUsers {
        id
      }
      schedule {
        id
      }
    }
  }
`

const UPDATE_GROUP_MEMBER = gql`
  mutation UpdateGroupMember($data: GroupMemberUpdateInput!, $where: GroupMemberWhereUniqueInput!) {
    updateGroupMember(data: $data, where: $where) {
      id
      password
      userName
      effectiveAt
      expiredAt
      linkUsers {
        id
      }
    }
  }
`

const EditGroupMember: React.FC<RouteComponentProps<{ id: string }>> = ({ history, match }) => {
  const { id } = match.params
  const { data } = useQuery(GET_GROUP_MEMBER, {
    variables: { where: { id } },
    fetchPolicy: 'cache-and-network'
  })

  const [handleUpdate, { loading: updateLoading }] = useMutation(UPDATE_GROUP_MEMBER, {
    onCompleted: data => {
      message.success('修改成功')
      history.push('/groupMembers/list')
    }
  })

  const handleSubmit = (values: any) => {
    const user = getUser()

    let postData = {
      ...values,
      user: {
        connect: { id: user.id }
      }
    }
    if (values.linkUsers && values.linkUsers.length > 0) {
      postData.linkUsers = {
        set: values.linkUsers.map((id: string) => ({ id }))
      }
    }
    if (values.schedule) {
      postData.schedule = {
        connect: {
          id: values.schedule
        }
      }
    }

    handleUpdate({
      variables: {
        data: postData,
        where: { id }
      }
    })
  }

  return (
    <Layout
      extra={
        <Link to="/groupMembers/list">
          <Button type="primary" icon="left">
            返回
          </Button>
        </Link>
      }
      title="编辑团员账号"
    >
      <GroupMemberForm initValue={data && data.groupMember} loading={updateLoading} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withRouter(EditGroupMember)
