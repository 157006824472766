import React, { useEffect, useState } from 'react'
import { Icon, Menu as AntMenu } from 'antd'
import logo from '../../../assets/images/logo.svg'
import styles from './index.module.scss'
import routes, { RouteItem } from '../../../utils/routes'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { getUser } from '../../../utils/auth'
const { SubMenu } = AntMenu

const renderMenu = (route: RouteItem): any => {
  if (route.children && route.children.length > 0) {
    return (
      !route.hide && (
        <SubMenu
          key={route.key}
          title={
            <span>
              <Icon type={route.icon} />
              <span>{route.displayName}</span>
            </span>
          }
        >
          {route.children.map(renderMenu)}
        </SubMenu>
      )
    )
  } else {
    return (
      !route.hide && (
        <AntMenu.Item key={route.key}>
          {route.displayName}
          <Link to={route.path} />
        </AntMenu.Item>
      )
    )
  }
}

type Props = {
  collapsed?: boolean
}

const Menu: React.FC<Props & RouteComponentProps> = ({ collapsed }) => {
  const userCookie = getUser()
  const [user, setUser] = useState(null)
  useEffect(() => {
    setUser(userCookie)
  }, [])
  console.log('menu render')
  return (
    <AntMenu style={{ height: '100%' }} mode="inline" theme="dark" inlineCollapsed={collapsed}>
      <div className={styles.logo}>
        <span>
          <img alt="logo" src={logo} />
          <h1>日上会员</h1>
        </span>
      </div>
      {routes(user).map(renderMenu)}
    </AntMenu>
  )
}

export default withRouter(Menu)
