import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Divider, Descriptions } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { getUser } from '../../../utils/auth'
import QRCode from 'qrcode'
import axios from 'axios'

const { Option } = Select

type FormProps = {
  loading?: boolean
  initValue?: { id: string; userName: string; password: string; phone: string; role: string }
  form: WrappedFormUtils
  onSubmit: (values: object) => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}

const UserForm: React.FC<FormProps> = ({ loading, onSubmit, form, initValue }) => {
  const [qrCodeBase64, setQrCodeBase64] = useState('')
  const [shortUrl, setShortUrl] = useState('')
  const { getFieldDecorator } = form
  const user = getUser()
  const isAdmin = user.role === 'admin'
  const frontEndUrl = `${document.location.protocol}//${window.location.host}/app/${user.id}`

  useEffect(() => {
    QRCode.toDataURL(frontEndUrl, {
      width: 300
    }).then(data => {
      setQrCodeBase64(data)
    })
  }, [setQrCodeBase64, frontEndUrl])

  useEffect(() => {
    axios
      .post(
        'https://dwz.cn/admin/v2/create',
        {
          Url: frontEndUrl
        },
        {
          headers: {
            token: '3e2bc93a3904c6273b7ba84661f47252'
          }
        }
      )
      .then(data => {
        setShortUrl(data.data.ShortUrl)
      })
  }, [])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    form.validateFields(async (err: any, values: any) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  return (
    <Fragment>
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="用户名">
          {getFieldDecorator('userName', {
            initialValue: initValue && initValue.userName,
            rules: [{ required: true, message: '请输入用户名' }]
          })(<Input placeholder="用户名" autoComplete="new-sh" />)}
        </Form.Item>
        <Form.Item label="密码">
          {getFieldDecorator('password', {
            initialValue: initValue && initValue.password,
            rules: [{ required: true, message: '请输入密码' }]
          })(<Input placeholder="密码" autoComplete="new-sh" />)}
        </Form.Item>
        <Form.Item label="手机号">
          {getFieldDecorator('phone', {
            initialValue: initValue && initValue.phone
          })(<Input placeholder="手机号" autoComplete="new-sh" />)}
        </Form.Item>
        {isAdmin && (
          <Form.Item label="权限">
            {getFieldDecorator('role', {
              initialValue: (initValue && initValue.role) || 'user',
              rules: [{ required: true, message: '请选择权限' }]
            })(
              <Select>
                <Option value="user">普通用户</Option>
                <Option value="admin">管理员</Option>
                {/* <Option value="block">打劫</Option> */}
              </Select>
            )}
          </Form.Item>
        )}
        <Form.Item
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: 16, offset: 8 }
          }}
        >
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Descriptions bordered column={1} title="前台页面信息">
        <Descriptions.Item label="短链接">
          <a target="blank" href={shortUrl || frontEndUrl}>
            {shortUrl || frontEndUrl}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="原链接">
          <a target="blank" href={frontEndUrl}>
            {frontEndUrl}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="二维码">
          <img src={qrCodeBase64} />
        </Descriptions.Item>
      </Descriptions>
    </Fragment>
  )
}

export default Form.create<FormProps>()(UserForm)
