import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import Form from '../form'
import Layout from '../../../components/layout'
import { message, Button } from 'antd'

const ADD_USER = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      userName
      password
      role
      phone
    }
  }
`

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [handleLogin, { loading }] = useMutation(ADD_USER, {
    onCompleted: data => {
      message.success('添加成功')
      history.push('/users/list')
    }
  })

  const handleSubmit = (values: object) => {
    handleLogin({ variables: { data: values } })
  }

  return (
    <Layout
      extra={
        <Link to="/schedules-builder/list">
          <Button type="primary" icon="left">
            返回
          </Button>
        </Link>
      }
      title="添加执行计划"
    >
      <Form loading={loading} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withRouter(Login)
