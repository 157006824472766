import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import ScheduleTable from './table'
import { graphql } from '@apollo/react-hoc'
import Layout from '../../../components/layout'
import { Button } from 'antd'
import { getUser } from '../../../utils/auth'
import { compose, withProps } from 'recompose'

export const GET_SCHEDULE = gql`
  query schedules(
    $where: ScheduleWhereInput
    $orderBy: ScheduleOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    schedules(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      name
    }
  }
`
type Props = {} & RouteComponentProps & any

const UserList: React.FC<Props> = ({ history, schedulesQuery: { loading, refetch, schedules } }) => {
  return (
    <Layout
      title="时间计划列表"
      extra={
        <Link to="/schedules/add">
          <Button type="primary" icon="plus">
            新增
          </Button>
        </Link>
      }
    >
      {schedules && schedules.length > 0 && (
        <ScheduleTable onDeleteSuccess={() => refetch()} loading={loading} dataSource={schedules} />
      )}
    </Layout>
  )
}

const withUserProps = withProps(() => ({
  user: getUser()
}))

export default compose<any, any>(
  withRouter,
  withUserProps,
  graphql<any>(GET_SCHEDULE, {
    name: 'schedulesQuery',
    options: props => ({
      variables: {
        orderBy: 'createdAt_DESC',
        where: {
          user: {
            id: props.user.id
          }
        }
      },
      fetchPolicy: 'cache-and-network'
    })
  })
)(UserList)
