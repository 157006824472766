import React from 'react'
import { Form, Icon, Input, Button } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import styles from './index.module.scss'

type FormProps = {
  loading?: boolean
  form: WrappedFormUtils
  onSubmit: (values: object) => void
}

const LoginForm: React.FC<FormProps> = ({ loading, onSubmit, form }) => {
  const { getFieldDecorator } = form

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    form.validateFields(async (err: any, values: any) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit} className={styles.login_form}>
      <h3>登陆</h3>
      <Form.Item>
        {getFieldDecorator('userName', {
          rules: [{ required: true, message: '请输入用户名' }]
        })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: '请输入密码' }]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="密码"
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button loading={loading} htmlType="submit" type="primary" className={styles.login_button}>
          登陆
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create<FormProps>()(LoginForm)
