import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import styles from './index.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { isLogin } from '../../utils/auth'
import { setLoginCookie } from '../../utils/auth'
import WrappedForm from './form'

const USER_LOGIN = gql`
  mutation UserLogin($data: UserLoginInput!) {
    userLogin(data: $data) {
      user {
        id
        userName
        role
      }
      token
    }
  }
`

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  isLogin() && history.push('/dashboard')

  const [handleLogin, { loading }] = useMutation(USER_LOGIN, {
    onCompleted: data => {
      if (data && data.userLogin && data.userLogin.token && data.userLogin.user) {
        setLoginCookie(data.userLogin.token, data.userLogin.user)
        history.push('/dashboard')
      }
    }
  })

  const handleSubmit = (values: object) => {
    handleLogin({ variables: { data: values } })
  }

  return (
    <div className={styles.bg}>
      <div className={styles.content}>
        <h2>
          欢迎使用 <br />
          后台管理系统
        </h2>
        <WrappedForm loading={loading} onSubmit={handleSubmit} />
      </div>
    </div>
  )
}

export default withRouter(Login)
