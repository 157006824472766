import React, { useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_DATETIME_DURATION } from '../list'
import { Select } from 'antd'
import * as R from 'ramda'

const { Option } = Select

interface DateTimeDurationSelectProps {
  onChange?: any
  value?: any
  onDetailChange?: any
}

const DateTimeDurationSelect: React.FC<DateTimeDurationSelectProps> = ({ onChange, value, onDetailChange }) => {
  const { loading, data } = useQuery(GET_DATETIME_DURATION, {
    variables: {
      orderBy: 'createdAt_DESC'
    },
    fetchPolicy: 'cache-and-network'
  })

  const handleChange = useCallback(
    (value, option) => {
      const result = value.map((val: string) => {
        const item: any = R.filter(R.propEq('id', val))(data.dateTimeDurations)
        return R.pluck('dateTimeDurationDetails')(item)
      })
      onDetailChange && onDetailChange(R.flatten(result))
      onChange && onChange(value)
    },
    [onChange, data]
  )

  return (
    <Select loading={loading} mode="multiple" style={{ width: '100%' }} value={value} onChange={handleChange}>
      {data &&
        data.dateTimeDurations &&
        data.dateTimeDurations.map((duration: any) => <Option value={duration.id}>{duration.note}</Option>)}
    </Select>
  )
}

export default DateTimeDurationSelect
