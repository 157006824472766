import React, { useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import Form from '../form'
import Layout from '../../../components/layout'
import { message, Button } from 'antd'
import { Moment } from 'moment'

const ADD_USER = gql`
  mutation CreateDateTimeDuration($data: DateTimeDurationCreateInput!) {
    createDateTimeDuration(data: $data) {
      id
    }
  }
`

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [handleCreateDateTimeDuration, { loading }] = useMutation(ADD_USER, {
    onCompleted: data => {
      message.success('添加成功')
      history.push('/date-time-duration/list')
    }
  })

  const handleSubmit = useCallback(
    async values => {
      const { note, dateTimeDurationList } = values
      console.log(dateTimeDurationList)
      if (dateTimeDurationList.length > 0) {
        await handleCreateDateTimeDuration({
          variables: {
            data: {
              note,
              dateTimeDurationDetails: {
                create: dateTimeDurationList.map((item: { startDate: Moment; endDate: Moment }) => ({
                  startDate: item.startDate.toISOString(),
                  endDate: item.endDate.toISOString()
                }))
              }
            }
          }
        })
      } else {
        message.error('请添加时间段')
      }
    },
    [handleCreateDateTimeDuration]
  )

  return (
    <Layout
      extra={
        <Link to="/date-time-duration/list">
          <Button type="primary" icon="left">
            返回
          </Button>
        </Link>
      }
      title="添加时间段"
    >
      <Form loading={loading} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withRouter(Login)
