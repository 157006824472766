import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, DatePicker, Checkbox, Switch } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import * as R from 'ramda'
import moment from 'moment'
import { GET_USERS } from '../../user/list'
import { useQuery } from '@apollo/react-hooks'
import { getUser } from '../../../utils/auth'
import { GET_SCHEDULE } from '../../schedule/list'
const { RangePicker } = DatePicker
const { Option } = Select

type FormProps = {
  loading?: boolean
  initValue?: {
    id: string
    userName: string
    password: string
    isDefault: boolean
    phone: string
    role: string
    linkUsers: any
    expiredAt: string
    effectiveAt: string
    schedule: any
  }
  form: WrappedFormUtils
  onSubmit: (values: object) => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}

const omit = (obj: { [key: string]: string }, arr: string[]) =>
  Object.keys(obj)
    .filter(k => !arr.includes(k))
    .reduce((acc: { [key: string]: string }, key) => ((acc[key] = obj[key]), acc), {})

const UserForm: React.FC<FormProps> = ({ loading, onSubmit, form, initValue }) => {
  const { getFieldDecorator } = form

  const userCookie = getUser()
  const [user, setUser] = useState<any>(null)
  useEffect(() => {
    setUser(userCookie)
  }, [])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const filtered = {
          ...values
          // effectiveAt: moment(values.dataRange[0].format('YYYY-MM-DD HH:mm:ss')).toISOString(),
          // expiredAt: moment(values.dataRange[1].format('YYYY-MM-DD HH:mm:ss')).toISOString()
        }
        onSubmit(omit(filtered, ['dataRange']))
      }
    })
  }

  const onSelectChange = (checkedValues: any) => {
    // console.log('checked = ', checkedValues)
  }

  const { loading: userLoading, data: userData, refetch: userRefetch } = useQuery(GET_USERS, {
    variables: {
      orderBy: 'createdAt_DESC'
    },
    fetchPolicy: 'cache-and-network'
  })

  const { loading: scheduleLoading, data: scheduleData, refetch: scheduleRefetch } = useQuery(GET_SCHEDULE, {
    variables: {
      orderBy: 'createdAt_DESC'
    },
    fetchPolicy: 'cache-and-network'
  })

  const options =
    (userData &&
      userData.users &&
      userData.users.map((user: any) => ({
        label: user.userName,
        value: user.id
      }))) ||
    []

  return (
    <Fragment>
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="用户名">
          {getFieldDecorator('userName', {
            initialValue: initValue && initValue.userName,
            rules: [{ required: true, message: '请输入用户名' }]
          })(<Input placeholder="用户名" autoComplete="new-sh" />)}
        </Form.Item>
        <Form.Item label="密码">
          {getFieldDecorator('password', {
            initialValue: initValue && initValue.password,
            rules: [{ required: true, message: '请输入密码' }]
          })(<Input placeholder="密码" autoComplete="new-sh" />)}
        </Form.Item>
        <Form.Item label="是否默认账号">
          {getFieldDecorator('isDefault', {
            valuePropName: 'checked',
            initialValue: initValue && initValue.isDefault
          })(<Switch />)}
        </Form.Item>
        {/* <Form.Item label="生效时间">
          {getFieldDecorator('dataRange', {
            initialValue: initValue &&
              initValue.effectiveAt &&
              initValue.expiredAt && [moment(initValue.effectiveAt), moment(initValue.expiredAt)],
            rules: [{ required: true, message: '请选择生效时间' }]
          })(
            <RangePicker
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')]
              }}
              style={{ width: '100%' }}
            />
          )}
        </Form.Item> */}

        {/* {user && user.role === 'block' && (
          <>
            {options && options.length > 0 && (
              <Form.Item label="关联账号">
                {getFieldDecorator('linkUsers', {
                  initialValue: initValue && initValue.linkUsers && R.pluck<any, any>('id', initValue.linkUsers)
                })(<Checkbox.Group options={options} onChange={onSelectChange} />)}
              </Form.Item>
            )}
            <Form.Item label="时间计划">
              {getFieldDecorator('schedule', {
                initialValue: initValue && initValue.schedule.id
              })(
                <Select>
                  {scheduleData &&
                    scheduleData.schedules &&
                    scheduleData.schedules.map((schedule: any) => (
                      <Option key={schedule.id} value={schedule.id}>
                        {schedule.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </>
        )} */}

        <Form.Item
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: 16, offset: 8 }
          }}
        >
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export default Form.create<FormProps>()(UserForm)
