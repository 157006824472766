import React, { useState } from 'react'
import { Menu as AntMenu, Icon, Card, Dropdown, Layout as AntLayout } from 'antd'
import styles from './index.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Menu from './menu'
import { isLogin, getUser, clearLoginCookie } from '../../utils/auth'
import { CardProps } from 'antd/lib/card'

const Layout: React.FC<RouteComponentProps & CardProps> = ({ children, history, staticContext, ...rest }) => {
  const [collapsed, setCollapsed] = useState(false)

  if (isLogin()) {
    const user = getUser()
    return (
      <div className={styles.layout}>
        <div style={{ minHeight: '100vh' }} className={collapsed ? styles.menu_collapsed : styles.menu}>
          <Menu collapsed={collapsed} />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.trigger}>
              <Icon onClick={() => setCollapsed(!collapsed)} type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </div>
            <div className={styles.right}>
              <Dropdown
                overlay={
                  <AntMenu>
                    <AntMenu.Item
                      onClick={() => {
                        clearLoginCookie()
                        history.push('/login')
                      }}
                    >
                      <Icon type="logout" />
                      退出登陆
                    </AntMenu.Item>
                  </AntMenu>
                }
              >
                <a className="ant-dropdown-link" href="#">
                  {user && user.userName} <Icon type="down" />
                </a>
              </Dropdown>
            </div>
          </div>
          <div className={styles.main}>
            <Card {...rest}>{children}</Card>
          </div>
        </div>
      </div>
    )
  }
  history.push('/login')
  return null
}

export default withRouter(Layout)
