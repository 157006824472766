import React, { useState, useCallback, useEffect } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import * as R from 'ramda'
import './index.scss'

interface MultiDatePickerProps extends Partial<DayPicker> {
  onChange?: (dates: any[]) => void
  value?: any[]
}

const MultiDatePicker: React.FC<MultiDatePickerProps> = ({ onChange, value, ...rest }) => {
  const [selectedDays, setSelectDays] = useState()

  useEffect(() => {
    setSelectDays(value)
  }, [value])

  const handleDayClick = useCallback(
    (day, { selected }) => {
      let selectedDaysClone = R.clone(selectedDays) || []
      if (selected) {
        const selectedIndex = selectedDaysClone.findIndex((selectedDay: Date) => DateUtils.isSameDay(selectedDay, day))
        selectedDaysClone.splice(selectedIndex, 1)
      } else {
        selectedDaysClone.push(day)
      }
      setSelectDays(selectedDaysClone)
      onChange && onChange(selectedDaysClone)
    },
    [selectedDays]
  )
  return (
    <DayPicker
      {...rest}
      disabledDays={{ before: new Date() }}
      selectedDays={selectedDays}
      onDayClick={handleDayClick}
      localeUtils={MomentLocaleUtils}
      locale="zh-CN"
    />
  )
}

export default MultiDatePicker
