import React from 'react'
import { Table, Divider, message, Popconfirm } from 'antd'
import { TableProps } from 'antd/lib/table/interface'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
    }
  }
`

type UserTableProps = {
  onDeleteSuccess: () => void
}

const UserTable: React.FC<UserTableProps & TableProps<any>> = ({ onDeleteSuccess, ...rest }) => {
  const [handleDelete, { loading }] = useMutation(DELETE_USER, {
    onCompleted: data => {
      message.success('删除成功')
      onDeleteSuccess()
    }
  })
  const columns = [
    {
      title: '行程名称',
      dataIndex: 'name',
      key: 'userName'
    },
    {
      title: '领队',
      dataIndex: 'guide.name',
      key: 'guide.name'
    },
    {
      title: '开始时间',
      dataIndex: 'start_at',
      key: 'start_at',
      render: (text: any, record: any) => moment(record.start_at).format('YYYY-MM-DD')
    },
    {
      title: '结束时间',
      dataIndex: 'end_at',
      key: 'end_at',
      render: (text: any, record: any) => moment(record.end_at).format('YYYY-MM-DD')
    }
  ]
  return <Table rowKey="id" {...rest} columns={columns} />
}

export default UserTable
