import React, { useState, useCallback, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  List,
  Typography,
  Steps,
  Col,
  Row,
  Table,
  message,
  Result,
  Card
} from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import TripSelect from '../../guide/trip-select'
import moment from 'moment'
import { GET_GROUPMEMBERS } from '../../groupMember/list'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { getUser } from '../../../utils/auth'
import { gql } from 'apollo-boost'
import { useHistory } from 'react-router'
import { expandedGroupMemberTripsRowRender } from '../../groupMember/list/table'

const { Step } = Steps

const CREATE_EXECUTE_SCHEDULE = gql`
  mutation CreateExecuteSchedule($data: ExecuteScheduleCreateInput!) {
    createExecuteSchedule(data: $data) {
      id
      note
      startDate
      endDate
    }
  }
`

type FormProps = {
  loading?: boolean
  initValue?: { id: string; userName: string; password: string; role: string }
  form: WrappedFormUtils
  onSubmit: (values: object) => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}

const UserForm: React.FC<FormProps> = ({ loading, onSubmit, form, initValue }) => {
  const { getFieldDecorator } = form

  const history = useHistory()
  const [selectedGroup, setSelectedGroup] = useState()
  const [executeScheduleList, setExecuteScheduleList] = useState<any[]>([])
  const [executeScheduleDurationList, setExecuteScheduleDurationList] = useState<any[]>([])
  const [selectedGroupMembers, setSelectedGroupMembers] = useState<string[]>([])
  const [formValue, setFormValue] = useState<{ note?: string }>({})
  const [stepCurrent, setStepCurrent] = useState(0)

  const { loading: groupMemberLoading, data, refetch } = useQuery(GET_GROUPMEMBERS, {
    variables: {
      orderBy: 'createdAt_DESC',
      where: {
        user: {
          id: getUser().id
        }
      },
      first: 999
    },
    fetchPolicy: 'cache-and-network'
  })

  const [handleCreateExecuteSchedule, { loading: createExecuteScheduleLoading }] = useMutation(
    CREATE_EXECUTE_SCHEDULE,
    {
      onCompleted: data => {
        handleNext()
      }
    }
  )

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      form.validateFields(async (err: any, values: any) => {
        if (!err) {
          setFormValue(values)
          const { frequency, note } = values
          const { start_at, end_at } = selectedGroup
          let next_at = start_at
          let result = []
          const timeFormat = 'YYYY-MM-DD HH:mm:ss'
          while (
            moment(next_at)
              .add('hours', frequency)
              .isBefore(moment(end_at))
          ) {
            next_at = moment(next_at).add('hours', frequency)
            result.push(next_at.format(timeFormat))
          }
          setExecuteScheduleList(
            [moment(start_at).format(timeFormat)].concat(result).concat([moment(end_at).format(timeFormat)])
          )
          handleNext()
        }
      })
    },
    [form, setFormValue]
  )

  const handleItemChange = useCallback(
    (e: any) => {
      setSelectedGroup(e)
    },
    [setSelectedGroup]
  )

  useEffect(() => {
    let result = []
    console.log(executeScheduleList)
    for (let i = 0; i < executeScheduleList.length; i++) {
      if (i > 0) {
        result.push([executeScheduleList[i - 1], executeScheduleList[i]])
      }
    }
    setExecuteScheduleDurationList(result)
  }, [executeScheduleList])

  const groupTableColumns = [
    {
      title: '团员用户名',
      dataIndex: 'userName',
      key: 'userName'
    }
  ]

  const handleNext = useCallback(() => {
    setStepCurrent(current => current + 1)
  }, [stepCurrent, setStepCurrent])

  const handlePrev = useCallback(() => {
    if (stepCurrent > 0) {
      setStepCurrent(current => current - 1)
    }
  }, [stepCurrent, setStepCurrent])

  const groupMemberRowSelection = {
    getCheckboxProps: (record: any) => ({
      disabled: !(record.groupMemberTrips.length > 0)
    }),
    onChange: (selectedRowKeys: any) => {
      setSelectedGroupMembers(selectedRowKeys)
    }
  }

  const handleCreateManyExecuteSchedule = useCallback(async () => {
    const executeScheduleDurationListLength = executeScheduleDurationList.length
    let tempSelectedGroupMember: any[] = []
    const cloneedGroupMember = JSON.parse(JSON.stringify(selectedGroupMembers))
    while (tempSelectedGroupMember.length < executeScheduleDurationListLength) {
      tempSelectedGroupMember = tempSelectedGroupMember.concat(cloneedGroupMember)
    }
    const result = executeScheduleDurationList.map((item, index) => ({
      startDate: moment(item[0]).toISOString(),
      endDate: moment(item[1]).toISOString(),
      groupMember: {
        connect: {
          id: tempSelectedGroupMember[index]
        }
      },
      priority: 0,
      user: {
        connect: {
          id: getUser().id
        }
      }
    }))
    const { note } = formValue
    await handleCreateExecuteSchedule({
      variables: {
        data: {
          note,
          startDate: moment(executeScheduleList[0]).toISOString(),
          endDate: moment(executeScheduleList[executeScheduleList.length - 1]).toISOString(),
          priority: 0,
          user: {
            connect: {
              id: getUser().id
            }
          },
          executeScheduleDetail: {
            create: result
          }
        }
      }
    })
  }, [selectedGroupMembers, executeScheduleDurationList, formValue])

  return (
    <div>
      <Steps current={stepCurrent}>
        <Step title="基础设置" />
        <Step title="预览执行计划" />
        <Step title="完成" />
      </Steps>
      <div style={{ paddingTop: 40 }}>
        <Form style={{ display: stepCurrent === 0 ? 'block' : 'none' }} {...formItemLayout} onSubmit={handleSubmit}>
          <Form.Item label="选择团队">
            {getFieldDecorator('groupId', {
              rules: [{ required: true, message: '请选择团队' }]
            })(<TripSelect onItemChange={handleItemChange} />)}
          </Form.Item>
          <Form.Item label="批次备注">
            {getFieldDecorator('note', {
              rules: [{ required: true, message: '输入批次备注' }]
            })(<Input placeholder="批次备注" autoComplete="new-sh" />)}
          </Form.Item>
          <Form.Item label="执行频率">
            {getFieldDecorator('frequency', {
              rules: [{ required: true, message: '请输入执行频率' }]
            })(<Input maxLength={3} placeholder="执行频率" autoComplete="new-sh" addonAfter="小时" addonBefore="每" />)}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { offset: 4 },
              sm: { offset: 4 }
            }}
          >
            <Button loading={loading} htmlType="submit" type="primary">
              下一步
            </Button>
          </Form.Item>
        </Form>
        <div style={{ display: stepCurrent === 1 ? 'block' : 'none' }}>
          <Row gutter={20}>
            <Col span={10}>
              <Card title="执行时间段">
                <List
                  bordered
                  dataSource={executeScheduleDurationList}
                  renderItem={(item, index) => (
                    <List.Item>
                      <Typography.Text mark>
                        <span style={{ width: 30, display: 'inline-block', textAlign: 'center' }}>{index + 1}.</span>
                      </Typography.Text>
                      {`${item[0]} ~ ${item[1]}`}
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col span={14}>
              {data && (
                <Card title="选择执行账号">
                  <Table
                    rowKey="id"
                    rowSelection={groupMemberRowSelection}
                    loading={groupMemberLoading}
                    columns={groupTableColumns}
                    dataSource={data.groupMembers}
                    expandedRowRender={expandedGroupMemberTripsRowRender}
                    pagination={false}
                  />
                  <div
                    style={{
                      visibility: selectedGroupMembers.length > 0 ? 'visible' : 'hidden',
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    已选择{selectedGroupMembers.length}人
                  </div>
                  <Button onClick={handlePrev} style={{ marginRight: 10 }}>
                    上一步
                  </Button>
                  <Button
                    disabled={!(selectedGroupMembers.length > 0)}
                    onClick={handleCreateManyExecuteSchedule}
                    loading={createExecuteScheduleLoading}
                    type="primary"
                  >
                    提交
                  </Button>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingTop: 20 }}></Col>
          </Row>
        </div>
        <Result
          style={{ display: stepCurrent === 2 ? 'block' : 'none' }}
          status="success"
          title="成功导入执行计划"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                history.push('/schedules-builder/list')
              }}
            >
              查看执行计划列表
            </Button>
          ]}
        />
      </div>
    </div>
  )
}

export default Form.create<FormProps>()(UserForm)
