import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import UserTable from './table'
import { graphql } from '@apollo/react-hoc'
import Layout from '../../../components/layout'
import { Button } from 'antd'
import { getUser } from '../../../utils/auth'
import { compose, withProps } from 'recompose'

export const GET_GUIDES = gql`
  query getGuides(
    $where: GuideWhereInput
    $orderBy: GuideOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    guides(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) {
      id
      userName
      password
      discount
      name
      guideIntegral {
        id
        total
        offlineLock
        offline
        onlineLock
        online
        limit
        free
        freeLock
        freeToGive
      }
      trips {
        id
        groupListItemId
        userName
        userId
        name
        created_at
        start_at
        end_at
      }
    }
  }
`
type Props = {} & RouteComponentProps & any

const UserList: React.FC<Props> = ({ history, guidesQuery: { loading, refetch, guides } }) => {
  return (
    <Layout
      title="领队列表"
      extra={
        <Link to="/guides/add">
          <Button type="primary" icon="plus">
            新增
          </Button>
        </Link>
      }
    >
      {guides && guides.length > 0 && (
        <UserTable onDeleteSuccess={() => refetch()} loading={loading} dataSource={guides} />
      )}
    </Layout>
  )
}

const withUserProps = withProps(() => ({
  user: getUser()
}))

export default compose<any, any>(
  withRouter,
  withUserProps,
  graphql<any>(GET_GUIDES, {
    name: 'guidesQuery',
    options: props => ({
      variables: {
        orderBy: 'createdAt_DESC',
        where: {
          user: {
            id: props.user.id
          }
        }
      },
      fetchPolicy: 'cache-and-network'
    })
  })
)(UserList)
