import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import DateTimeDurationForm from '../form'
import Layout from '../../../components/layout'
import { message, Button } from 'antd'
import { getUser } from '../../../utils/auth'

const GET_USER = gql`
  query GetDateTimeDuration($where: DateTimeDurationWhereUniqueInput!) {
    dateTimeDuration(where: $where) {
      id
      dateTimeDurationName
      password
      role
      phone
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateDateTimeDuration($data: DateTimeDurationUpdateInput!, $where: DateTimeDurationWhereUniqueInput!) {
    updateDateTimeDuration(data: $data, where: $where) {
      id
      dateTimeDurationName
      password
      role
      phone
    }
  }
`

const EditDateTimeDuration: React.FC<RouteComponentProps<{ id: string }>> = ({ history, match }) => {
  const { id } = match.params
  const { data } = useQuery(GET_USER, {
    variables: { where: { id } },
    fetchPolicy: 'cache-and-network'
  })

  const [handleUpdate, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onCompleted: data => {
      message.success('修改成功')
      history.push('/dateTimeDurations/list')
    }
  })

  const handleSubmit = (values: object) => {
    handleUpdate({ variables: { data: values, where: { id } } })
  }

  const user = getUser()
  const isAdmin = user.role === 'admin'

  return (
    <Layout
      extra={
        isAdmin && (
          <Link to="/dateTimeDurations/list">
            <Button type="primary" icon="left">
              返回
            </Button>
          </Link>
        )
      }
      title="编辑用户"
    >
      <DateTimeDurationForm initValue={data && data.dateTimeDuration} loading={updateLoading} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withRouter(EditDateTimeDuration)
