import Dashboard from '../views/dashboard'
import Login from '../views/login'

import UserAdd from '../views/user/add'
import UserList from '../views/user/list'
import UserEdit from '../views/user/edit'

import GroupMemberAdd from '../views/groupMember/add'
import GroupMemberList from '../views/groupMember/list'
import GroupMemberEdit from '../views/groupMember/edit'

import ScheduleAdd from '../views/schedule/add'
import ScheduleList from '../views/schedule/list'
import ScheduleEdit from '../views/schedule/edit'

import GuideAdd from '../views/guide/add'
import GuideList from '../views/guide/list'

import TripList from '../views/trip/list'
import ConfigForm from '../views/config/form'

import ScheduleBuilderAdd from '../views/schedule-builder/add'
import ScheduleBuilderList from '../views/schedule-builder/list'
import ScheduleBuilderEdit from '../views/schedule-builder/edit'

import DateTimeDurationAdd from '../views/date-time-duration/add'
import DateTimeDurationList from '../views/date-time-duration/list'
import DateTimeDurationEdit from '../views/date-time-duration/edit'

import BlockScheduleAdd from '../views/block-schedule/add'
import BlockScheduleList from '../views/block-schedule/list'

import App from '../views/app'

export type RouteItem = {
  key: string
  path: string
  hide?: boolean
  displayName?: string
  icon?: string
  component?: any
  children?: RouteItem[]
}

const routes = (user: any) => [
  {
    hide: true,
    key: 'app',
    path: '/app/:userId',
    displayName: '前台页面',
    icon: 'number',
    component: App,
    children: []
  },
  {
    key: 'login',
    path: '/login',
    displayName: '登陆',
    icon: 'user',
    component: Login,
    hide: true
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    displayName: 'dashboard',
    icon: 'dashboard',
    component: Dashboard,
    hide: true
  },
  {
    key: 'config',
    path: '/config',
    displayName: '系统设置',
    icon: 'setting',
    children: [
      {
        key: 'config.form',
        path: '/config/form',
        displayName: '基本信息',
        component: ConfigForm
      }
    ]
  },
  {
    key: 'user',
    path: '/user',
    displayName: '用户管理',
    icon: 'user',
    hide: user && user.role !== 'admin',
    children: [
      {
        key: 'users.add',
        path: '/users/add',
        displayName: '添加用户',
        component: UserAdd
      },
      {
        key: 'users.list',
        path: '/users/list',
        displayName: '用户列表',
        component: UserList
      },
      {
        key: 'user.add',
        path: '/users/:id',
        displayName: '编辑用户',
        component: UserEdit,
        hide: true
      }
    ]
  },
  {
    key: 'guides',
    path: '/guides',
    displayName: '领队管理',
    icon: 'idcard',
    children: [
      {
        key: 'guides.add',
        path: '/guides/add',
        displayName: '添加领队',
        component: GuideAdd
      },
      {
        key: 'guides.list',
        path: '/guides/list',
        displayName: '领队列表',
        component: GuideList
      }
    ]
  },
  {
    key: 'trips',
    path: '/trips',
    displayName: '行程管理',
    icon: 'number',
    hide: true,
    children: [
      {
        key: 'trips.list',
        path: '/trips/list',
        displayName: '所有行程',
        component: TripList
      }
    ]
  },
  {
    key: 'groupMembers',
    path: '/groupMembers',
    displayName: '团员管理',
    icon: 'team',
    children: [
      {
        key: 'groupMembers.add',
        path: '/groupMembers/add',
        displayName: '添加团员',
        component: GroupMemberAdd
      },
      {
        key: 'groupMembers.list',
        path: '/groupMembers/list',
        displayName: '团员列表',
        component: GroupMemberList
      },
      {
        key: 'groupMembers.add',
        path: '/groupMembers/:id',
        displayName: '编辑用户',
        component: GroupMemberEdit,
        hide: true
      }
    ]
  },
  // {
  //   key: 'schedules',
  //   path: '/schedules',
  //   displayName: '时间计划',
  //   icon: 'user',
  //   hide: user && user.role !== 'block',
  //   children: [
  //     {
  //       key: 'schedules.add',
  //       path: '/schedules/add',
  //       displayName: '添加计划',
  //       component: ScheduleAdd
  //     },
  //     {
  //       key: 'schedules.list',
  //       path: '/schedules/list',
  //       displayName: '计划列表',
  //       component: ScheduleList
  //     },
  //     {
  //       key: 'schedules.add',
  //       path: '/schedules/:id',
  //       displayName: '编辑',
  //       component: ScheduleEdit,
  //       hide: true
  //     }
  //   ]
  // },
  {
    key: 'schedules.builder',
    path: '/schedule-builder',
    displayName: '执行计划',
    icon: 'schedule',
    children: [
      {
        key: 'schedules.builder.add',
        path: '/schedules-builder/add',
        displayName: '添加计划',
        component: ScheduleBuilderAdd
      },
      {
        key: 'schedules.builder.list',
        path: '/schedules-builder/list',
        displayName: '计划列表',
        component: ScheduleBuilderList
      },
      {
        key: 'schedules.builder.add',
        path: '/schedules-builder/:id',
        displayName: '编辑',
        component: ScheduleBuilderEdit,
        hide: true
      }
    ]
  },
  {
    key: 'date-time-duration',
    path: '/date-time-duration',
    displayName: '时间段管理',
    hide: user && user.role !== 'admin',
    icon: 'history',
    children: [
      {
        key: 'date-time-duration.add',
        path: '/date-time-duration/add',
        displayName: '添加时间段',
        component: DateTimeDurationAdd
      },
      {
        key: 'date-time-duration.list',
        path: '/date-time-duration/list',
        displayName: '时间段列表',
        component: DateTimeDurationList
      },
      {
        key: 'date-time-duration.add',
        path: '/date-time-duration/:id',
        displayName: '编辑',
        component: DateTimeDurationEdit,
        hide: true
      }
    ]
  },
  {
    key: 'block-schedule',
    path: '/block-schedule',
    displayName: '优先执行计划',
    icon: 'disconnect',
    hide: user && user.role !== 'admin',
    children: [
      {
        key: 'block-schedule.add',
        path: '/block-schedule/add',
        displayName: '创建优先计划',
        component: BlockScheduleAdd
      },
      {
        key: 'block-schdeule.list',
        path: '/block-schedule/list',
        displayName: '优先执行列表',
        component: BlockScheduleList
      }
    ]
  }
]

export default routes
