import React from 'react'
import { Table, Divider, message, Popconfirm, Button } from 'antd'
import { TableProps, ColumnProps } from 'antd/lib/table/interface'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { rowClassName } from '../../../guide/list/table'

const DELETE_BLOCK_SCHEDULE = gql`
  mutation DeleteBlockSchedule($where: BlockScheduleWhereUniqueInput!) {
    deleteBlockSchedule(where: $where) {
      id
    }
  }
`

type BlockScheduleProps = {
  onDeleteSuccess: () => void
}

const BlockScheduleTable: React.FC<BlockScheduleProps & TableProps<any>> = ({ onDeleteSuccess, ...rest }) => {
  const [handleDelete, { loading: deleteLoading }] = useMutation(DELETE_BLOCK_SCHEDULE, {
    onCompleted: data => {
      message.success('删除成功')
      onDeleteSuccess()
    }
  })

  const executeScheduleDetailRowRender = (record: { executeScheduleDetail: any[] }) => {
    const columns = [
      {
        title: '执行账号',
        dataIndex: 'groupMember.userName',
        key: 'groupMember.userName'
      },
      {
        title: '优先级',
        dataIndex: 'priority',
        key: 'priority'
      },
      {
        title: '开始时间',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: '结束时间',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    ]
    return (
      <Table
        rowKey="id"
        rowClassName={rowClassName}
        columns={columns}
        dataSource={record.executeScheduleDetail}
        pagination={false}
      />
    )
  }

  const executeSchedulesRowRender = (record: { executeSchedules: any[] }) => {
    const columns = [
      {
        title: '备注',
        dataIndex: 'note',
        key: 'note'
      },
      {
        title: '执行用户',
        dataIndex: 'user.userName',
        key: 'user.userName'
      },
      {
        title: '开始时间',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: '结束时间',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    ]
    return (
      <Table
        rowKey="id"
        columns={columns}
        rowClassName={rowClassName}
        expandedRowRender={executeScheduleDetailRowRender}
        dataSource={record.executeSchedules}
        pagination={false}
      />
    )
  }

  const columns: ColumnProps<any>[] = [
    {
      title: '备注',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text: any, record: any) => (
        <span>
          <Popconfirm
            placement="leftBottom"
            title="确认删除？"
            onConfirm={() => handleDelete({ variables: { where: { id: record.id } } })}
            okText="删除"
            cancelText="取消"
          >
            <Button loading={deleteLoading} type="link">
              删除
            </Button>
          </Popconfirm>
        </span>
      )
    }
  ]
  return <Table rowKey="id" {...rest} expandedRowRender={executeSchedulesRowRender} columns={columns} />
}

export default BlockScheduleTable
