import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import Form from '../form'
import Layout from '../../../components/layout'
import { message, Button } from 'antd'
import { getUser } from '../../../utils/auth'

const ADD_GROUP_MEMBER = gql`
  mutation AddRishangGroupMemberAndTrips($userName: String!, $password: String!, $user: String!) {
    addRishangGroupMemberAndTrips(userName: $userName, password: $password, user: $user) {
      id
      password
      userName
    }
  }
`

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [addGroupMember, { loading }] = useMutation(ADD_GROUP_MEMBER, {
    onCompleted: data => {
      message.success('添加成功')
      history.push('/groupMembers/list')
    }
  })

  const handleSubmit = (values: any) => {
    const { userName, password } = values
    const user = getUser()
    let postData = {
      ...values,
      user: {
        connect: { id: user.id }
      }
    }
    if (values.linkUsers && values.linkUsers.length > 0) {
      postData.linkUsers = {
        connect: values.linkUsers.map((id: string) => ({ id }))
      }
    }
    if (values.schedule) {
      postData.schedule = {
        connect: {
          id: values.schedule
        }
      }
    }
    addGroupMember({
      variables: {
        userName,
        password,
        user: user.id
      }
    })
  }

  return (
    <Layout
      extra={
        <Link to="/groupMembers/list">
          <Button type="primary" icon="left">
            返回
          </Button>
        </Link>
      }
      title="添加团员账号"
    >
      <Form loading={loading} onSubmit={handleSubmit} />
    </Layout>
  )
}

export default withRouter(Login)
