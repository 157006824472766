import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import UserTable from './table'
import Layout from '../../../components/layout'
import { getUser } from '../../../utils/auth'

export const GET_TRIPS = gql`
  query trips(
    $where: TripWhereInput
    $orderBy: TripOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    trips(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) {
      name
      guide {
        name
      }
      created_at
      start_at
      end_at
    }
  }
`

const UserList: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, data, refetch } = useQuery(GET_TRIPS, {
    variables: {
      where: {
        user: {
          id: getUser().id
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  })
  return (
    <Layout title="行程列表">
      {data && <UserTable onDeleteSuccess={() => refetch()} loading={loading} dataSource={data.trips} />}
    </Layout>
  )
}

export default withRouter(UserList)
