import React from 'react'
import { Table, message, Popconfirm } from 'antd'
import { TableProps } from 'antd/lib/table/interface'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { rowClassName } from '../../../guide/list/table'

const DELETE_USER = gql`
  mutation DeleteExecuteSchedule($where: ExecuteScheduleWhereUniqueInput!) {
    deleteExecuteSchedule(where: $where) {
      id
    }
  }
`

type ExecuteSchedulerTableProps = {
  onDeleteSuccess: () => void
}

const ExecuteSchedulerTable: React.FC<ExecuteSchedulerTableProps & TableProps<any>> = ({
  onDeleteSuccess,
  ...rest
}) => {
  const [handleDelete, { loading }] = useMutation(DELETE_USER, {
    onCompleted: data => {
      message.success('删除成功')
      onDeleteSuccess()
    }
  })

  const expandedRowRender = (record: { executeScheduleDetail: any[] }) => {
    const columns = [
      {
        title: '执行账号',
        dataIndex: 'groupMember.userName',
        key: 'groupMember.userName'
      },
      {
        title: '开始时间',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: '结束时间',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    ]
    return (
      <Table
        rowKey="id"
        columns={columns}
        rowClassName={rowClassName}
        dataSource={record.executeScheduleDetail}
        pagination={false}
      />
    )
  }

  const columns = [
    {
      title: '备注',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: '生效时间',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '失效时间',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作',
      key: 'action',
      render: (text: any, record: any) => (
        <span>
          <Popconfirm
            placement="leftBottom"
            title="确认删除？"
            onConfirm={() => handleDelete({ variables: { where: { id: record.id } } })}
            okText="删除"
            cancelText="取消"
          >
            <a>删除</a>
          </Popconfirm>
        </span>
      )
    }
  ]
  return (
    <Table rowKey="id" {...rest} rowClassName={rowClassName} expandedRowRender={expandedRowRender} columns={columns} />
  )
}

export default ExecuteSchedulerTable
