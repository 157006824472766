import React from 'react'
import { Form, Input, Button, Select } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
const { Option } = Select

type FormProps = {
  loading?: boolean
  initValue?: { id: string; userName: string; password: string; role: string }
  form: WrappedFormUtils
  onSubmit: (values: object) => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}

const UserForm: React.FC<FormProps> = ({ loading, onSubmit, form, initValue }) => {
  const { getFieldDecorator } = form

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    form.validateFields(async (err: any, values: any) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="账号">
        {getFieldDecorator('userName', {
          initialValue: initValue && initValue.userName,
          rules: [{ required: true, message: '请输入账号' }]
        })(<Input placeholder="账号" autoComplete="new-sh" />)}
      </Form.Item>
      <Form.Item label="密码">
        {getFieldDecorator('password', {
          initialValue: initValue && initValue.password,
          rules: [{ required: true, message: '请输入密码' }]
        })(<Input placeholder="密码" autoComplete="new-sh" />)}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: { span: 24, offset: 0 },
          sm: { span: 16, offset: 8 }
        }}
      >
        <Button loading={loading} htmlType="submit" type="primary">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create<FormProps>()(UserForm)
