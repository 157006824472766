import React, { useState, useCallback } from 'react'
import { Table, Divider, message, Popconfirm, Button, Modal, Tag } from 'antd'
import { TableProps } from 'antd/lib/table/interface'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import TripSelect from '../../../guide/trip-select'
import { rowClassName } from '../../../guide/list/table'

const DELETE_USER = gql`
  mutation DeleteGroupMember($where: GroupMemberWhereUniqueInput!) {
    deleteGroupMember(where: $where) {
      id
    }
  }
`

const UPDATE_GROUP_MEMBER = gql`
  mutation UpdateRishangGroupMemberTripInfo($groupMemberId: String!) {
    updateRishangGroupMemberTripInfo(groupMemberId: $groupMemberId) {
      id
    }
  }
`

const JOIN_GROUP = gql`
  mutation JoinGroup($mobile: String!, $password: String!, $groupId: Int!) {
    joinGroup(mobile: $mobile, password: $password, groupId: $groupId) {
      code
    }
  }
`

type GroupMemberTableProps = {
  onDeleteSuccess: () => void
}

export const expandedGroupMemberTripsRowRender = (record: { groupMemberTrips: any[] }) => {
  const columns = [
    {
      title: '行程名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '领队名',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: '开始时间',
      dataIndex: 'start_at',
      key: 'start_at',
      render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '结束时间',
      dataIndex: 'end_at',
      key: 'end_at',
      render: (text: string) => moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  ]
  return <Table columns={columns} rowClassName={rowClassName} dataSource={record.groupMemberTrips} pagination={false} />
}

const GroupMemberTable: React.FC<GroupMemberTableProps & TableProps<any>> = ({ onDeleteSuccess, ...rest }) => {
  const [handleDelete, { loading }] = useMutation(DELETE_USER, {
    onCompleted: data => {
      message.success('删除成功')
      onDeleteSuccess()
    }
  })

  const [handleJoinGroup, { loading: joinGroupLoading }] = useMutation(JOIN_GROUP, {
    onCompleted: data => {
      message.success('入团成功')
    }
  })

  const [handleUpdate, { loading: upLoading }] = useMutation(UPDATE_GROUP_MEMBER, {
    onCompleted: data => {
      message.success('更新成功')
      onDeleteSuccess()
    }
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [currentRecord, setCurrentRecord] = useState()
  const [currentTrip, setCurrentTrip] = useState()

  const toggleModal = useCallback(() => {
    setModalVisible(state => !state)
  }, [setModalVisible])

  const columns = [
    {
      title: '团员用户名',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: '是否默认账号',
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (text: any) => (text ? <Tag color="blue">是</Tag> : <Tag color="red">否</Tag>)
    },
    {
      title: '操作',
      key: 'action',
      render: (text: any, record: any) => (
        <span>
          <Link to={`/groupMembers/${record.id}`}>编辑</Link>
          <Divider type="vertical" />
          <Popconfirm
            placement="leftBottom"
            title="确认删除？"
            onConfirm={() => handleDelete({ variables: { where: { id: record.id } } })}
            okText="删除"
            cancelText="取消"
          >
            <a>删除</a>
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            placement="leftBottom"
            title="确认更新？"
            onConfirm={() => handleUpdate({ variables: { groupMemberId: record.id } })}
            okText="更新"
            cancelText="取消"
          >
            <a>更新团队信息</a>
          </Popconfirm>
          <Divider type="vertical" />
          <a
            onClick={() => {
              setCurrentRecord(record)
              toggleModal()
            }}
          >
            入团
          </a>
        </span>
      )
    }
  ]
  return (
    <div>
      <Modal
        title="入团"
        confirmLoading={joinGroupLoading}
        onOk={async () => {
          if (!currentTrip) {
            message.error('请选择团队')
            return false
          }
          const { userName, password } = currentRecord
          try {
            await handleJoinGroup({
              variables: {
                mobile: userName,
                password: password,
                groupId: currentTrip
              }
            })
            toggleModal()
          } catch (error) {}
        }}
        visible={modalVisible}
        onCancel={toggleModal}
      >
        <TripSelect
          onChange={(e: React.SetStateAction<boolean>) => {
            setCurrentTrip(e)
          }}
        />
      </Modal>
      <Table
        rowClassName={rowClassName}
        expandedRowRender={expandedGroupMemberTripsRowRender}
        rowKey="id"
        {...rest}
        columns={columns}
      />
    </div>
  )
}

export default GroupMemberTable
